export const REDUCER_GENERAL_APP = 'entities';

export const PERSIST_PROGRAMS = 'program';
export const PERSIST_COURSES = 'course';
export const PERSIST_COUNTRIES = 'countries';
export const PERSIST_SCHEMES = 'schemes';
export const PERSIST_EDUCATION_LEVELS = 'educationLevels';
export const PERSIST_LESSONS = 'lessons';
export const PERSIST_CONTENT_TYPES = 'contentTypes';
export const PERSIST_CONTENT = 'content';
export const PERSIST_UI = 'ui';
export const PERSIST_TESTS = 'test';
export const PERSIST_SAML = 'saml';
export const PERSIST_CALENDARS = 'calendars';
export const PERSIST_GROUPS = 'groups';
export const PERSIST_SCHOOL_YEARS = 'years';
export const PERSIST_LEMONADE_TEST = 'lemonadeTest';
export const PERSIST_LANGS = 'langs';
export const PERSIST_HISTORY = 'history';

export const REDUCER_PROGRAMS = 'program';
export const REDUCER_COURSES = 'course';
export const REDUCER_COUNTRIES = 'countries';
export const REDUCER_SCHEMES = 'schemes';
export const REDUCER_EDUCATION_LEVELS = 'educationLevels';
export const REDUCER_LESSONS = 'lesson';
export const REDUCER_CONTENT_TYPES = 'contentTypes';
export const REDUCER_CONTENT = 'content';
export const REDUCER_UI = 'ui';
export const REDUCER_APPINFO = 'appInfo';
export const REDUCER_TESTS = 'test';
export const REDUCER_TASKS = 'task';
export const REDUCER_SAML = 'saml';
export const REDUCER_CALENDARS = 'calendars';
export const REDUCER_NOTIFICATIONS = 'notifications';
export const REDUCER_PROJECTION = 'projection';
export const REDUCER_USERS = 'users';
export const REDUCER_LICENSES = 'licenses';
export const REDUCER_GROUPS = 'groups';
export const REDUCER_SCHOOL_YEARS = 'years';
export const REDUCER_LEMONADE_TEST = 'lemonadeTest';
export const REDUCER_LANGS = 'langs';
export const REDUCER_HISTORY = 'history';
export const REDUCER_SYSTEM_SETTINGS = 'systemSettings';