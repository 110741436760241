import { put, takeLatest } from 'redux-saga/effects';
import { getSetting } from '../crud/systemSettings.crud';
import { REDUCER_SYSTEM_SETTINGS } from './conf';

export const actionTypes = {
  GetSystemSettings: 'system/GET_SYSTEM_SETTINGS',
  SetSystemSettings: 'system/SET_SYSTEM_SETTINGS',
};

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetSystemSettings:
      return { ...state, loading: true };

    case actionTypes.SetSystemSettings: {
      const { response } = action.payload;
      if (response?.data?.data) {
        return { ...state, settings: response.data.data, loading: false };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
};

export const selectors = {
  getSystemSettings: (state) => state[REDUCER_SYSTEM_SETTINGS]?.settings,
};

export const actions = {
  getSystemSettings: () => ({ type: actionTypes.GetSystemSettings }),
  setSystemSettings: (response) => ({ type: actionTypes.SetSystemSettings, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetSystemSettings, function* getSystemSettingsSaga() {
    try {
      const response = yield getSetting();
      yield put(actions.setSystemSettings(response));
    } catch (error) {
      console.error('Error fetching system settings:', error);
    }
  });
}
