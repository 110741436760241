import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const ProgressBar = ({ progress, size, background, withLabel, reverse, text, colorBar }) => {
  return (
    <div className={clsx(`progress-bar progress-bar--size-${size}`, `progress-bar--background-${background}`, { 'progress-bar--reverse': reverse })}>
      {text && <span className="progress-bar__text">{text}</span>}
      <div className="progress-bar__bar">
        <div className="progress-bar__progress" style={{ width: progress + '%', backgroundColor: colorBar }}></div>
      </div>
      {withLabel && <div className="progress-bar__label">{progress}% </div>}
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  size: PropTypes.oneOf(['default', 'medium']),
  background: PropTypes.oneOf(['default', 'white', 'grey']),
  colorBar: PropTypes.string,
  withLabel: PropTypes.bool,
  reverse: PropTypes.bool,
  text: PropTypes.string,
};

ProgressBar.defaultProps = {
  progress: 10,
  size: 'default',
  background: 'default',
  withLabel: true,
  reverse: false,
};

export default ProgressBar;
