import axios from 'axios';

// V2
export const LESSON_V2 = '/front/lessons';
export const LESSON_ITEMS_V2 = '/front/lesson-items';
export const LESSON_ITEMS_V2_REORDER = '/front/lesson-items/reorder';
// V3
export const LESSON_V3 = '/v3/front/lessons';
export const LESSON_ITEMS_V3 = '/v3/front/lesson-items';

//CONTENTS
export const CONTENTS = '/cms/contents';

//COURSES
export const COURSES = '/front/courses';

// UNIDAD DELETE
export function deleteSectionOfLesson(params) {
  return axios.delete(LESSON_ITEMS_V2, params);
}

// UNIDAD UPDATE
export function putSectionOfLesson(params) {
  return axios.put(`${LESSON_ITEMS_V2}/${params.guid}`, params.data);
}

// Add new section to lesson
export function postNewContentInLessonItem(params) {
  return axios.post(LESSON_ITEMS_V2, params);
}

// Duplicate mint lesson
export function duplicateMintLesson(params) {
  return axios.post(`${LESSON_V2}/${params.guid}/mint-lesson-copy`, params);
}

export function putReorder(params) {
  return axios.put(LESSON_ITEMS_V2, params.data);
}

export function getLesson(guid, courseGuid) {
  let path = `${LESSON_V2}/${guid}`;
  if (courseGuid) path = path + `?referencedCourseGuid=${courseGuid}`;
  return axios.get(path);
}

export function getItemsOfLesson(params, courseGuid) {
  let path = `${LESSON_V2}/${params.guid}/items`;
  let customized = params.customized ? params.customized : null;
  if (courseGuid) {
    path = path + `?courseGuid=${courseGuid}`;
  }

  return axios.get(path, { params: { ...(customized && { customized_by: customized }) } });
}

export function getItemsOfLessonV3(params, courseGuid) {
  let path = `${LESSON_V3}/${params.guid}/items`;
  let customized = params.customized ? params.customized : null;
  if (courseGuid) {
    path = path + `?course_guid=${courseGuid}`;
  }

  return axios.get(path, { params: { ...(customized && { customized_by: customized }) } });
}

export function getLessonItemMessages(params) {
  return axios.get(`${COURSES}/${params.courseGuid}/messages/${params.courseMessageGuid}`, params.data);
}

export function getLessonItems(lessonItemGuid, courseGuid, customized) {
  let lessonItemGuidSanitized = lessonItemGuid.includes('?') ? lessonItemGuid.split('?')[0] : lessonItemGuid;
  let path = `${LESSON_ITEMS_V2}/${lessonItemGuidSanitized}`;
  let customized_by = customized ? customized : null;
  if (courseGuid) {
    path = path + `?courseGuid=${courseGuid}`;
  }
  return axios.get(path, { params: { ...(customized && { customized_by }) } });
}

export function getLessonItemV3(lessonItemGuid, lessonGuid, courseGuid, customized) {
  let lessonItemGuidSanitized = lessonItemGuid.includes('?') ? lessonItemGuid.split('?')[0] : lessonItemGuid;
  let path = `${LESSON_V3}/${lessonGuid}/items/${lessonItemGuidSanitized}`;
  let customized_by = customized ? customized : null;
  if (courseGuid) {
    path = path + `?course_guid=${courseGuid}`;
  }

  return axios.get(path, { params: { ...(customized_by && { customized_by }) } });
}

export function getContent(lessonItemGuid) {
  return axios.get(`${CONTENTS}/${lessonItemGuid}`);
}

export function getTimetable(courseGuid) {
  return axios.get(`${COURSES}/${courseGuid}/timetable`);
}

//IA
export function sendTextToIA(lessonItemGuid, toAge, text) {
  return axios.post(`${LESSON_ITEMS_V2}/${lessonItemGuid}/ia`, {
    toAge: toAge,
    text: text,
  });
}

// MENSAJE
export function postMessaje(params) {
  return axios.post(`${COURSES}/${params.courseGuid}/messages`, params.data);
}

export function deleteMessaje(params) {
  return axios.delete(`${COURSES}/${params.courseGuid}/messages`, { data: params.data });
}

export function putMessaje(params) {
  return axios.put(`${COURSES}/${params.courseGuid}/messages/${params.messajeGuid}`, params.data);
}

// NEW CONTENT
export function postNewContent(params) {
  return axios.post(CONTENTS, params);
}

export function updateContent(params) {
  return axios.put(CONTENTS, { guid: params.guid, data: params.data });
}

// GET MS TOKEN
export function getMsToken() {
  return axios.get('msir-launch-params');
}
