import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../atoms/Button';

const FooterButtons = ({ buttonIconSucess, buttonTextCancel, buttonTextSucess, onSuccess, onCancel, disabledSuccess, className }) => {
  return (
    <div className={clsx('footer-buttons', className)}>
      {onCancel && <Button type="reset" variant="inline" color="transparent" onClick={onCancel} text={buttonTextCancel} size="small" />}
      {onSuccess && (
        <Button type="submit" variant="inline" onClick={onSuccess} text={buttonTextSucess} disabled={disabledSuccess} size="small" icon={buttonIconSucess} />
      )}
    </div>
  );
};

FooterButtons.propTypes = {
  buttonIconSucess: PropTypes.string,
  buttonTextCancel: PropTypes.string,
  buttonTextSucess: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  disabledSuccess: PropTypes.bool,
  className: PropTypes.string,
};

FooterButtons.defaultProps = {
  buttonTextCancel: 'Cancel',
  buttonTextSucess: 'Confirm',
  typeButtonSuccess: '',
  disabledSuccess: false,
};

export default FooterButtons;
