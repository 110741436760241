export const PERSIST_I18N = 'i18n';
export const PERSIST_AUTH = 'i2c_auth';
export const PERSIST_CONTENTS_BOOKS = 'i2c_contents-books';
export const PERSIST_CONTENTS = 'i2c_contents';
export const PERSIST_COURSES = 'course';
export const PERSIST_GROUPS = 'groups';
export const PERSIST_UI = 'ui';
export const PERSIST_TYPES = 'i2c_types';
export const PERSIST_DLOS = 'i2c_dlos';
export const PERSIST_TOPICS = 'i2c_topics';
export const PERSIST_ASSET = 'i2c_ASSET';
export const PERSIST_PROJECTS_LEVELS = 'i2c_projectsLevels';
export const PERSIST_SCHOOL_YEARS = 'years';
export const PERSIST_LESSONS = 'lessons';
export const PERSIST_CONTENT_TYPES = 'contentTypes';
export const PERSIST_HISTORY = 'history';
export const PERSIST_PROGRAMS = 'program';
export const PERSIST_ORGANIZATION = 'organization';
export const PERSIST_DEMO = 'demo';
export const PERSIST_AI_ASSISTANT = 'aiAssistant';

export const PERSIST_COUNTRIES = 'countries';
export const PERSIST_EDUCATION_LEVELS = 'i2c_educationLevels';
export const PERSIST_COLLECTIONS = 'i2c_collections';
export const PERSIST_LEARNING_OBJECTIVES = 'i2c_learningObjectives';
export const PERSIST_LANGS = 'i2c_langs';
export const PERSIST_CUSTOM_TAGS = 'i2c_customTags';
export const PERSIST_TEMPLATES = 'templates';
export const PERSIST_SYSTEM_SETTINGS = 'systemSettings';
export const REDUCER_ASSET = 'asset';
export const REDUCER_AUTH = 'auth';
export const REDUCER_CONTENTS = 'contents';
export const REDUCER_CONTENT = 'content';
export const REDUCER_CONTENTS_BOOKS = 'contentsBooks';
export const REDUCER_COURSES = 'course';
export const REDUCER_DLOS = 'DLO';
export const REDUCER_I18N = PERSIST_I18N;
export const REDUCER_PROJECTS_LEVELS = 'projectsLevels';
export const REDUCER_TOPICS = 'topics';
export const REDUCER_TASKS = 'tasks';
export const REDUCER_PROJECTION = 'projection';
export const REDUCER_SEARCHS = 'searchs';
export const REDUCER_TYPES = 'types';
export const REDUCER_UI = PERSIST_UI;
export const REDUCER_GAMIFICATION = 'gamification';

export const REDUCER_ORGANIZATION = PERSIST_ORGANIZATION;

export const REDUCER_COUNTRIES = 'countries';
export const REDUCER_EDUCATION_LEVELS = 'educationLevels';
export const REDUCER_COLLECTIONS = 'collections';
export const REDUCER_LEARNING_OBJECTIVES = 'learningObjectives';
export const REDUCER_LANGS = 'langs';
export const REDUCER_CUSTOM_TAGS = 'customTags';
export const REDUCER_SCHOOL_YEARS = 'years';
export const REDUCER_GROUPS = 'groups';
export const REDUCER_CALENDARS = 'calendars';
export const REDUCER_NOTIFICATIONS = 'notifications';
export const REDUCER_LESSONS = 'lesson';
export const REDUCER_CONTENT_TYPES = 'contentTypes';
export const REDUCER_TEMPLATES = 'templates';
export const REDUCER_HISTORY = 'history';
export const REDUCER_PROGRAMS = 'program';

export const REDUCER_DEMO = 'demo';
export const REDUCER_AI_ASSISTANT = 'aiAssistant';
export const REDUCER_SYSTEM_SETTINGS = 'systemSettings';
